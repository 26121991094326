import { selectIsAuthenticated, selectProfile, setProfile } from "@/store/auth"
import { removeAxiosAccessToken } from "@/utils/axios"
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {createSearchParams, Link, NavLink, useLocation, useSearchParams} from "react-router-dom"
import { BoxArrowRight, List, Person, XLg } from "react-bootstrap-icons"
import { useQuery } from "@/hooks/media"
import React from "react"


const NavbarMenu: React.FC = () => {
    const dispatch = useDispatch()
    const isAuthenticated = useSelector(selectIsAuthenticated)
    const profile = useSelector(selectProfile)
    
    const {t, i18n} = useTranslation()
    const location = useLocation()
    const isLg = useQuery("(max-width: 992px)")
    const [searchParam] = useSearchParams()

    const handleAccountLogout = (e: React.MouseEvent) => {
        e.preventDefault()

        dispatch(setProfile(null))
        removeAxiosAccessToken()
        localStorage.removeItem('token')
    }

    const changeLanguage = (to: string) => {
        //Agar sistema tili o'zgarayotgan tilga bo'lmasa
        // if (i18n.language.toString() !== to.toString()) {
        //     i18n.changeLanguage(to)
        // }

        let cleanUrl = location.pathname.replace(/\/+$/, '') + '/';
        for(const k of i18n.options.supportedLngs || []) {
            if (cleanUrl.startsWith('/' + k + '/')) {
                cleanUrl = cleanUrl.substring(3)
                break;
            }
        }

        //Agar default tilga teng bo'lsa
        if (to.toString() === i18n.options.fallbackLng.toString()) {
            to = cleanUrl
        } else {
            to = "/" + to + cleanUrl
        }

        let params = createSearchParams(searchParam).toString()
        if (params) {
            params = "?" + params
        }

        window.location.href = (to.replace(/\/+$/, '') || '/') + params
        // navigate(to.replace(/\/+$/, '') || '/')
    }

    const handleCloseNavbar = () => {        
        const btn = document.querySelector(".navbar-toggler")
        const event = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
        });

        btn.dispatchEvent(event)
    }

    return (
        <Navbar bg="dark" expand="lg">
            <Container className="justify-content-start">
                <Navbar.Toggle>
                    <List />
                </Navbar.Toggle>
                <Navbar.Brand as={Link} to="" className="p-0">
                    <img src={require("@/assets/img/logo.png")} className="logo-img" alt="Choykhona.Zone" />
                </Navbar.Brand>
                <Nav.Link as={NavLink} to="/select" className="text-uppercase d-block d-lg-none text-danger ms-auto ff-link">{t("Сделать заказ")}</Nav.Link>
                {isAuthenticated ? 
                <Nav.Link onClick={handleAccountLogout} className="text-uppercase d-block d-lg-none text-danger ms-4 ff-link">
                    <BoxArrowRight />  ({profile.first_name})
                </Nav.Link> :
                <Nav.Link as={NavLink} to="account/login" className="text-uppercase d-block d-lg-none text-danger ms-4 ff-link">
                    <Person />
                </Nav.Link>
                }
                
                <Navbar.Collapse id="choykhona-nav">
                    <div className="d-flex d-lg-none align-items-center">
                        <div className="text-white">Choykhona.Zone</div>
                        <Navbar.Toggle className="ms-auto">
                            <XLg />
                        </Navbar.Toggle>
                    </div>
                    <Nav className="ms-auto text-nowrap" onClick={isLg ? null : handleCloseNavbar}>
                        <Nav.Link as={NavLink} to="" end className="text-uppercase ff-link">{t("Главная")}</Nav.Link>
                        <Nav.Link as={NavLink} to="list" className="text-uppercase ff-link">{t("Каталог чайхан")}</Nav.Link>
                        {/*<Nav.Link as={Link} to="a" className="text-uppercase ff-link">{t("Прайс и меню")}</Nav.Link>*/}
                        <Nav.Link as={Link} to="gallery" className="text-uppercase ff-link">{t("Фотогалерея")}</Nav.Link>
                        <Nav.Item className="nav-vr-line mx-3" />
                        {!isAuthenticated && <Nav.Link as={Link} to="/select" className="text-uppercase ff-link">{t("Сделать заказ")}</Nav.Link>}
                        {isAuthenticated && <Nav.Link as={Link} to="/orders" className="text-uppercase ff-link">{t("Мои заказы")}</Nav.Link>}
                        <Nav.Item className="nav-vr-line mx-3" />

                        {isAuthenticated ? 
                        <>
                        <Nav.Link onClick={handleAccountLogout} className="d-xl-block d-none text-uppercase ff-link">{t("Выход")} ({profile.first_name})</Nav.Link>
                        <Nav.Link onClick={handleAccountLogout} className="text-uppercase d-none d-lg-block d-xl-none text-danger ff-link">
                            <BoxArrowRight />  ({profile.first_name})
                        </Nav.Link></> : <>
                        <Nav.Link as={Link} to="account/register" className="text-uppercase ff-link">{t("Регистрация")}</Nav.Link>
                        <Nav.Link as={NavLink} to="account/login" className="d-xl-block d-none text-uppercase ff-link">{t("Авторизация")}</Nav.Link>
                        <Nav.Link as={NavLink} to="account/login" className="text-uppercase d-none d-lg-block d-xl-none text-danger ff-link">
                            <Person />
                        </Nav.Link></>}

                        <NavDropdown title={i18n.language} className="text-uppercase languages ff-link d-none d-lg-block" align="end">
                            <NavDropdown.Item onClick={() => changeLanguage(i18n.language)} className="text-uppercase ff">{i18n.language}</NavDropdown.Item>
                            {i18n.language !== 'uz' && <NavDropdown.Item onClick={() => changeLanguage('uz')} className="text-uppercase ff">uz</NavDropdown.Item>}
                            {i18n.language !== 'ru' && <NavDropdown.Item onClick={() => changeLanguage('ru')} className="text-uppercase ff">ru</NavDropdown.Item>}
                            {i18n.language !== 'en' && <NavDropdown.Item onClick={() => changeLanguage('en')} className="text-uppercase ff">en</NavDropdown.Item>}
                        </NavDropdown>
                        
                        <Nav.Item className="d-block d-lg-none">
                            <div className="d-flex">
                                <Nav.Link onClick={() => changeLanguage('uz')} className={"ff-link " + (i18n.language === 'uz' ? "text-white" : "")}>UZ</Nav.Link>
                                <Nav.Link onClick={() => changeLanguage('ru')} className={"ms-4 ff-link " + (i18n.language === 'ru' ? "text-white" : "")}>RU</Nav.Link>
                                <Nav.Link onClick={() => changeLanguage('en')} className={"ms-4 ff-link " + (i18n.language === 'en' ? "text-white" : "")}>EN</Nav.Link>
                            </div>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavbarMenu;