import * as Yup from 'yup';
import moment from "moment";
import {TOrderData} from "@/types/order";

export const OrderDataValidator = Yup.object().shape({
    cabin: Yup.number().required(),
    booking_time: Yup.array().of(
        Yup.number()
    ).min(1),
    how_many_people: Yup.number().required().min(1),
    arrival_date: Yup.date().transform((value, originalValue) => {
        if (originalValue instanceof Date) {
            return originalValue
        }

        const m = moment(originalValue, "YYYY-MM-DD")
        return m.isValid() ? m.toDate() : null
    }).required().nullable()
})

export const IsValidOrderData: (data: TOrderData) => boolean = (data) => {
    return OrderDataValidator.isValidSync(data)
}