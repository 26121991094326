import { TDeviceUserInfo } from "@/types/account";
import { axiosClient } from "@/utils/axios";
import { getDeviceNameId } from "@/utils/device";
import { LoaderFunction } from "react-router-dom";
import {TResponse} from "@/types/common";



export const AccountDeviceUserInfoLoader: LoaderFunction = async () => {
    const {device_id} = getDeviceNameId()
        
    const deviceToken = localStorage.getItem('device_token')

    if (!deviceToken) {
        return null;
    }
    
    const resp = await axiosClient.post<never, TResponse<TDeviceUserInfo>>("/account/device-user-info/", {
        "device_token": deviceToken,
        device_id
    })

    if (resp.is_success) {
        return resp.data
    } 
    
    localStorage.removeItem('device_token')
    return null;
}
