import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {selectSettings} from "../store/common";
import {useSelector} from "react-redux";
import React from "react";

const Footer: React.FC = () => {
    const {t} = useTranslation();
    const settings = useSelector(selectSettings)

    return <div className="py-5 img" style={{
        backgroundImage: `url(${require('@/assets/img/footer-bg.png')})`
    }}>
    <Container className="text-white">
        <Row>
            <Col lg="3" className="text-center">
                <div className="text-danger text-uppercase">{t("Контакты")}</div>
                <small className="text-muted d-block mt-3">{settings.address}</small>
                <small className="d-block"><a className="text-muted text-decoration-none" href={"tel:" + settings.phone1}>{settings.phone1}</a></small>
                {settings.phone2 && <small className="d-block"><a className="text-muted text-decoration-none" href={"tel:" + settings.phone1}>{settings.phone1}</a></small>}
                {settings.email1 && <small className="d-block"><a className="text-muted text-decoration-none" href={"mailto:" + settings.email1}>{settings.email1}</a></small>}
                {settings.email2 && <small className="d-block"><a className="text-muted text-decoration-none" href={"mailto:" + settings.email2}>{settings.email2}</a></small>}
            </Col>
            <Col lg="6" className="text-center">
                <img src={require("@/assets/img/logo.png")} alt={""} />
                <div className="mt-3">
                    {t("Пожалуй лучший сервис и блюда во всем Узбекистане.")}
                </div>
                {settings.phone1}
                <div className="my-3">
                    {settings.facebook}
                </div>
                <small className="text-muted d-block">
                    &copy; {new Date().getFullYear()} Choykhona.zone. All Rights reserved.
                </small>
            </Col>
            <Col lg="3" className="text-center">
                <div className="text-danger text-uppercase">{t("Рабочие часы")}</div>
                <small className="text-muted d-block mt-3">{settings.schedule.split("\n").map((item, i) => <div key={i}>{item}</div>)}</small>
            </Col>
        </Row>
    </Container>
    </div>
}

export default Footer;
