
// import {sayHello} from "@sharecode/common";
import React from 'react';
import {
  createRoutesFromElements,
  Outlet,
  Route, useSearchParams
} from "react-router-dom"

import { PublicRoute, ProtectedRoute, SSRProtectRoute } from '@/utils/route';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/scss/fonts.scss';
import '@/assets/scss/app.scss';
import '@/assets/scss/bootstrap.scss';
import '@/assets/scss/index.scss';

import NavbarMenu from '@/widgets/NavbarMenu';
import { AccountDeviceUserInfoLoader } from '@/pages/account/loaders';
import {
  CatalogAlbumLoader, CatalogCabinLoader,
  CatalogGalleryLoader,
  CatalogIndexLoader,
  CatalogInfoLoader,
  CatalogListLoader
} from '@/pages/catalog/loaders';
import ErrorPage from "@/pages/ErrorPage";
import Footer from "@/widgets/Footer";
import {OrderListLoader, OrderLoader, OrderSelectLoader} from "@/pages/order/loaders";
import {IsTelegramContext} from "@/utils/context"

const CatalogIndex = React.lazy(() => import('@/pages/catalog/CatalogIndex'))
const CatalogList = React.lazy(() => import('@/pages/catalog/CatalogList'))
const CatalogInfo = React.lazy(() => import("@/pages/catalog/CatalogInfo"))
const CatalogGallery = React.lazy(() => import("@/pages/catalog/CatalogGallery"))
const CatalogAlbum = React.lazy(() => import("@/pages/catalog/CatalogAlbum"))
const CatalogCabin = React.lazy(() => import("@/pages/catalog/CatalogCabin"))

const AccountLogin = React.lazy(() => import('@/pages/account/AccountLogin'))
const AccountRegister = React.lazy(() => import('@/pages/account/AccountRegister'))

const OrderSelect = React.lazy(() => import("@/pages/order/OrderSelect"))
const OrderCheckout = React.lazy(() => import("@/pages/order/OrderCheckout"))
const OrderView = React.lazy(() => import("@/pages/order/OrderView"))
const OrderList = React.lazy(() => import("@/pages/order/OrderList"))

function App() {
  const [searchParams,] = useSearchParams()
  const isTelegram = (searchParams.get("tg") || "false") === "true";

  return (<>
    <IsTelegramContext.Provider value={isTelegram}>
      {!isTelegram && <NavbarMenu />}
      <Outlet />
      {!isTelegram && <Footer/>}
      {isTelegram && <div style={{height: "30px"}}>&nbsp;</div>}
    </IsTelegramContext.Provider>
  </>);
}

export function createRoutes() {
  const routes = (<>
    <Route path="" element={<CatalogIndex />} errorElement={<ErrorPage />} loader={CatalogIndexLoader}/>
    <Route path="list" element={<CatalogList />} errorElement={<ErrorPage />} loader={CatalogListLoader}/>
    <Route path="info/:id" element={<CatalogInfo />} errorElement={<ErrorPage />} loader={CatalogInfoLoader}/>
    <Route path="cabin/:id" element={<CatalogCabin />} errorElement={<ErrorPage />} loader={CatalogCabinLoader}/>
    <Route path="gallery" element={<CatalogGallery />} errorElement={<ErrorPage />} loader={CatalogGalleryLoader}/>
    <Route path="album/:id" element={<CatalogAlbum />} errorElement={<ErrorPage />} loader={CatalogAlbumLoader} />
    <Route path="checkout" element={<OrderCheckout />} errorElement={<ErrorPage />} loader={OrderLoader}/>
    <Route path="select" element={<OrderSelect />} errorElement={<ErrorPage />} loader={OrderSelectLoader}/>

    <Route element={<SSRProtectRoute />}>
      <Route element={<ProtectedRoute />}>
        <Route path="orders" element={<OrderList />} errorElement={<ErrorPage />} loader={OrderListLoader} />
        <Route path="order/:id" element={<OrderView />} errorElement={<ErrorPage />} />
      </Route>

      <Route path="account" element={<PublicRoute />}>
        <Route path="login" element={<AccountLogin />} loader={AccountDeviceUserInfoLoader} />
        <Route path="register" element={<AccountRegister />} />
      </Route>
    </Route>
  </>)

  return createRoutesFromElements(<>

      <Route path="en/*" element={<App/>}>
        {routes}
      </Route>
      <Route path="uz/*" element={<App/>}>
        {routes}
      </Route>
      <Route path="*" element={<App/>}>
        {routes}
      </Route>

  </>)
}

