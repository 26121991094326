/*eslint-disable no-template-curly-in-string*/

export const mixed = {
  default: '${path}da xatolik mavjud',
  required: '${path} maydoniga ma\'lumot kiritilmagan',
  oneOf: '${path} maydoni ${values} qiymatlardan bittasi emas',
  notOneOf: '${path} maydoni ${values} qiymatlarini qabul qilmaydi',

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  notType: ({ path, type, value, originalValue }: any) => {
    const isCast = originalValue != null && originalValue !== value;
    let msg =
      `${path} maydoni \`${type}\` turiga tegishli emas. ` +
      (isCast
        ? `\`${originalValue}\` dan olingan o`
        : 'O') +
      `xirgi qiymat \`${value}\` ga teng.`
      
    if (value === null) {
      msg += `\n Agar "null" bo‘sh qiymat bo‘lsa, sxemada \`.nullable()\` qilib belgilanganligiga ishonch hosil qiling`;
    }

    return msg;
  },
};


export const string = {
  length: '${path} maydoni ${length}ta belgidan iborat emas',
  min: '${path} maydoni kamida ${min}ta belgidan iborat bo‘lishi lozim',
  max: '${path} maydoni ko‘pi bilan ${max}ta belgidan iborat bo‘lishi mumkin',
  matches: '${path} maydon qiymati "${regex}" muntazam ifodaga mos emas',
  email: '${path} maydon qiymati e-mail emas',
  url: '${path} maydon qiymati havola emas',
  trim: '${path} mayqon qiymati boshida yoki oxirida bo‘shliqlar mavjud',
  lowercase: '${path} mayqon qiymat kichik harflardan iborat emas',
  uppercase: '${path} mayqon qiymat bosh (katta) harflardan iborat emas',
};

export const number = {
  min: '${path} maydon qiymati ${min}dan kichik',
  max: '${path} maydon qiymati ${max}dan katta',
  lessThan: '${path} maydon qiymati ${less}dan kichik emas',
  moreThan: '${path} maydon qiymati ${more}dan katta emas',
  positive: '${path} maydon qiymati musbat son emas',
  negative: '${path} maydon qiymati manfiy son emas',
  integer: '${path} maydon qiymati butun son emas',
};

export const date = {
  min: '${path} maydon qiymati ${min} sanandan oldin bo‘lmasligi lozim',
  max: '${path} maydon qiymati ${max} sanadan keyin bo‘lmasligi lozim',
};

export const boolean = {
  isValue: '${path} maydon qiymati ${value} bo‘lishi shart',
};

export const object = {
  noUnknown: '${path} maydon qiymatida noma\'lum kalitlar mavjud',
};

export const array = {
  min: '${path} maydon kamida ${min}ta elementdan iborat bo‘lishi lozim',
  max: '${path} maydon ko‘pi bilan ${max} elementdan iborat bo‘lishi mumkin',
};
