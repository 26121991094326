import {OrderDataValidator} from "@/pages/catalog/validators";
import {axiosClient} from "@/utils/axios";
import {TResponse, TResponseList} from "@/types/common";
import {TOrder, TOrderDataValid} from "@/types/order";
import {TChoyxona} from "@/types/catalog";
import {TCatalogInfoOrderFormValues} from "@/pages/catalog/types";


export const OrderLoader = async ({request}: {request: Request}) => {
    const url = new URL(request.url)
    const searchParams: TCatalogInfoOrderFormValues = {
        cabin: "",
        booking_time: [],
        how_many_people: "",
        arrival_date: ""
    }

    for (const [key, value] of url.searchParams.entries()) {
        if (key == "booking_time") {
            searchParams.booking_time.push(value)
        } else if (key in searchParams) {
            searchParams[key as keyof Omit<TCatalogInfoOrderFormValues, "booking_time">] = value
        }
    }

    if (!OrderDataValidator.isValidSync(searchParams)) {
        return null;
    }

    const resp = await axiosClient.post<never, TResponse<TOrderDataValid>>(`order/info/`, searchParams);
    if (!resp.is_success) {
        return null;
    }

    const resp_choyxona = await axiosClient.get<never, TResponse<TChoyxona>>(`catalog/info/${resp.data.choyxona}/`)
    if (!resp_choyxona.is_success) {
        return null;
    }

    return {
        "choyxona": resp_choyxona.data,
        "data": resp.data
    };
}


export const OrderListLoader = async () => {
    const resp = await axiosClient.get<never, TResponseList<TOrder>>('order/list/')
    if (resp.is_success) {
        return resp.data
    }

    return null
}


export const OrderSelectLoader = async () => {
    const resp_choyxona = await axiosClient.get<never, TResponse<TChoyxona>>(`catalog/info/1/`)
    if (!resp_choyxona.is_success) {
        return null;
    }

    return {
        "choyxona": resp_choyxona.data
    }
}