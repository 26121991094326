import axios from "axios"
import i18n from "@/i18n"

axios.defaults.headers['Accept-Language'] = "uz-UZ";

const config = {
    baseURL: process.env.baseURL || process.env.apiUrl || "/api/v1/"
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        config.headers["Accept-language"] = i18n.language
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        const data = response.data;
        data.code = response.status
        data.is_success = data.status === 'success'
        data.is_fail = data.status === 'fail'
        data.is_error = data.status === 'error'

        return data;
    },
    function (error) {
        const data = {
            code: typeof error.response != 'undefined' ? error.response.status : 404,
            is_success: false,
            is_fail: false,
            is_error: true,
            status: 'error',
            msg: typeof error.response != 'undefined' ? error.response.statusText : error.message
        }

        // if (data.code === 401) {
        //
        // }

        return data
    }
);

export function setAxiosAccessToken(access_token: string) {
    _axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
}

export function removeAxiosAccessToken() {
    _axios.defaults.headers.common['Authorization'] = null
}

export const axiosClient = _axios;
