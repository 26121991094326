import React from "react"
import store from "@/store"
import { Navigate, Outlet } from "react-router-dom";


export function ProtectedRoute({ children }: React.PropsWithChildren) {
    const isAuthenticated = !!store.getState().auth.profile
    
    if (!isAuthenticated) {
        return <Navigate to={"/account/login"} />
    }

    return <>{children ? children : <Outlet />}</>
}

export function PublicRoute({ children }: React.PropsWithChildren) {
    const isAuthenticated = !!store.getState().auth.profile
    if (isAuthenticated) {
        return <Navigate to={"/"} />
    }

    return <>{children ? children : <Outlet />}</>
}

export function SSRProtectRoute({children}: React.PropsWithChildren) {
    if (process.env.REACT_APP_SSR) {
        return (<div>Not supported</div>)
    }

    return <>{children ? children : <Outlet />}</>
}
