import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TSettings} from "../types/common";
import {RootState} from "./index";

type TCommonInitialState = {
    settings: TSettings | null
}

export const commonSlice = createSlice({
    name: "common",
    initialState: {
        settings: null
    } as TCommonInitialState,
    reducers: {
        setSettings(state, action: PayloadAction<TSettings>) {
            state.settings = action.payload
        }
    }
})

export const { setSettings } = commonSlice.actions

export const selectSettings = (state: RootState) => state.common.settings

export default commonSlice.reducer