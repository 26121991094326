import { axiosClient } from "@/utils/axios"
import {TRandomItemsAlbum, TChoyxona, TSingleItemAlbum, TAlbum} from "@/types/catalog";
import {TPaginated, TResponse, TResponseList} from "@/types/common";

export type TCatalogInfoLoader = {
    choyxona: TChoyxona,
    album: TRandomItemsAlbum
}

export const CatalogIndexLoader = async () => {
    const resp = await axiosClient.get<never, TResponseList<TChoyxona>>("catalog/list/homepage/")
    if (resp.is_success) {
        let idx = 0;
        while (resp.data.count < 3) {
            resp.data.count += 1
            resp.data.results.push(resp.data.results[idx++])
        }
        
        return resp.data
    }

    return null
}

export const CatalogListLoader = async ({request}: {request: Request}) => {
    const result: {
        list: TPaginated<TChoyxona> | null
    } = {
        list: null
    }

    // let resp_country = await axiosClient.get<never, TResponseList<TCountry>>("common/countries/");
    // if (resp_country.is_success) {
    //     result.countries = resp_country.data.results
    // }

    const url = new URL(request.url)

    const resp = await axiosClient.get<never, TResponseList<TChoyxona>>("catalog/list/?" + url.searchParams.toString());
    if (resp.is_success) {
        result.list = resp.data
    }

    return result
}


export const CatalogInfoLoader = async ({params}: {params: Record<string, string>}) => {
    const resp = await axiosClient.get<never, TResponse<TChoyxona>>(`catalog/info/${params.id}/`)
    if (resp.is_success) {
        const response: TCatalogInfoLoader = {
            choyxona: resp.data,
            album: null
        }

        if (resp.data.album) {
            const resp_album = await axiosClient.get<never, TResponse<TRandomItemsAlbum>>(`catalog/album/${resp.data.album}/random/`)
            if (resp_album.is_success) {
                response.album = resp_album.data
            }
        }

        return response
    }

    return null
}

export const CatalogCabinLoader = async ({params}: {params: Record<string, string>}) => {
    const resp = await axiosClient.get<never, TResponse<TChoyxona>>(`catalog/info/${params.id}/`)
    if (resp.is_success) {
        return {
            choyxona: resp.data,
            album: null
        } as TCatalogInfoLoader
    }

    return null
}

export const CatalogGalleryLoader = async () => {
    const resp = await axiosClient.get<never, TResponseList<TSingleItemAlbum>>('catalog/gallery/');
    if (resp.is_success) {
        return resp.data
    }

    return null
}

export const CatalogAlbumLoader = async ({params}: {params: Record<string, string>}) => {
    const resp = await axiosClient.get<never, TResponse<TAlbum>>(`catalog/album/${params.id}/`)
    if (resp.is_success) {
        return resp.data
    }

    return null
}