import i18n from "i18next"
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

export async function loadI18n() {
    await i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            detection: {
                order: ['path'],
            },
            fallbackLng: 'ru',
            supportedLngs: ['uz', 'ru', 'en'],
            debug: false,
            interpolation: {
                escapeValue: false
            }
        })
}

export default i18n

