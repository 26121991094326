import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'

import { createRoutes } from './App';
import store from "@/store"
import { setPermissions, setProfile } from './store/auth';
import {setSettings} from "./store/common";

import {axiosClient, removeAxiosAccessToken, setAxiosAccessToken} from './utils/axios';
import {
  createBrowserRouter, RouterProvider
} from "react-router-dom"
import { TProfile } from './types/account';
import Loading from './widgets/Loading';
import {TResponse, TSettings} from "./types/common";
import {loadI18n} from "./i18n"

import * as Yup from "yup";
import * as uz from "@/utils/uz";
import {ru} from "yup-locales";
import i18n from "i18next";

const urlParams = new URLSearchParams(window.location.search)

let accessToken =  urlParams.get('token')
if (!accessToken) {
  accessToken = localStorage.getItem('token')
}

if (accessToken) {
  setAxiosAccessToken(accessToken)
}

(async (is_ssr) => {
  await loadI18n()

  //Agar accessToken bo'lsa, faolligini tekshiramiz
  if (accessToken) {
    const resp = await axiosClient.get<never, TResponse<TProfile>>('account/profile/')
    if (resp.is_success) {
      store.dispatch(setProfile(resp.data.user))
      store.dispatch(setPermissions(resp.data.perms))
    }

    const isAuthenticated = !!store.getState().auth.profile
    if (!isAuthenticated) {
      removeAxiosAccessToken()
      localStorage.removeItem('token')
    }
  }

  const settings_resp = await axiosClient.get<never, TResponse<TSettings>>('common/settings/');
  if (settings_resp.is_success) {
    store.dispatch(setSettings(settings_resp.data))
  } else {
    alert("Please, try again!!!")
    return
  }

  const router = createBrowserRouter(createRoutes())
  const rootEl = document.getElementById('root');

  // set locale
  if (i18n.language !== 'en') {
    Yup.setLocale({
      'uz': uz, 'ru': ru
    }[i18n.language])
  }

  const RootApp = (
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback={<div className="suspense-loading"><Loading /></div>}>
          <RouterProvider router={router} />
        </Suspense>
      </Provider>
    </React.StrictMode>
  )

  if (is_ssr) {
    ReactDOM.hydrateRoot(rootEl, RootApp);
  } else {
    ReactDOM.createRoot(rootEl).render(RootApp);
  }

})(process.env.REACT_APP_SSR)

