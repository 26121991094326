import { TUser, TUserPerms } from "@/types/account"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import { RootState } from "."

type TAuthInitialState = {
  profile: TUser | null,
  permissions: TUserPerms | null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    profile: null,
    permissions: null
  } as TAuthInitialState,
  reducers: {
    setProfile: (state, action: PayloadAction<TUser>) => {
      state.profile = action.payload
    },
    setPermissions: (state, action: PayloadAction<TUserPerms>) => {
      state.permissions = action.payload
    }
  },
})

export const { setProfile, setPermissions } = authSlice.actions

export const selectProfile = (state: RootState) => state.auth.profile
export const selectIsAuthenticated = (state: RootState) => !!state.auth.profile

export default authSlice.reducer
