import { DeviceUUID } from "device-uuid"


export function getDeviceNameId() {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dd = new (DeviceUUID as any)()
    const ag = dd.parse()

    const device_id = dd.get()
    const device_name = `${ag.browser} ${ag.version} (${ag.platform} / ${ag.os})`

    return {
        device_name,
        device_id
    }
}